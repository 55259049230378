/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/common/layout/Footer";
import { useSelector } from "react-redux";
import Login from "../../components/common/auth/Login";
import Register from "../../components/common/auth/Register";
import ResetPassword from "../../components/common/auth/ResetPassword";
import VerifyEmail from "../../components/common/auth/VerifyEmail";
import FareCalculator from "../../components/passenger/home/FareCalculator";
import FirstLastTime from "../../components/passenger/home/FirstLastTime";
import LanguageChange from "../../components/common/layout/LanguageChange";
import AddReportItem from "../../components/passenger/lostFound/AddReportItem";
import NewPassword from "../../components/common/auth/NewPassword";
import ComplaintDetail from "../../components/corporate/grievance/Home/ComplaintDetail";
import ComplaintDetailComponent from "../../components/corporate/vigilance/Home/ComplaintDetail";
import SearchPopup from "../../components/common/layout/SearchPopup";
import TourDetailPopup from "../../components/passenger/tours/TourDetailPopup";
import DownloadLoader from "../../components/common/form/DownloadLoader";
import AddRemarksComplaint from "../../components/corporate/grievance/Home/AddRemarksComplaint";
import Feedback from "../../components/passenger/home/Feedback";
import ChatBot from "../../components/common/chat/ChatBot";
import GoGreenPointsInfo from "../../components/common/layout/GoGreenPointsInfo";
import ChatBotIcon from "../../components/common/chat/ChatBotIcon";

const MainLayout = () => {
    const user: any = useSelector((state: any) => state.auth.user);
    const loginOpen: boolean = useSelector((state: any) => state.data.loginOpen);
    const registerOpen: boolean = useSelector((state: any) => state.data.registerOpen);
    const resetPasswordOpen: boolean = useSelector((state: any) => state.data.resetPasswordOpen);
    const verifyEmailOpen: boolean = useSelector((state: any) => state.data.verifyEmailOpen);
    const newPasswordOpen: boolean = useSelector((state: any) => state.data.newPasswordOpen);
    const fareOpen: boolean = useSelector((state: any) => state.data.fareOpen);
    const firstlastTime: boolean = useSelector((state: any) => state.data.firstlastTime);
    const reportItemOpen: boolean = useSelector((state: any) => state.data.reportItemOpen);
    const langOpen: boolean = useSelector((state: any) => state.data.langOpen);
    const complaintDetail: boolean = useSelector((state: any) => state.data.complaintDetail);
    const searchOpen: boolean = useSelector((state: any) => state.data.searchOpen);
    const tourOpen: boolean = useSelector((state: any) => state.data.tourOpen);
    const downloadLoader: boolean = useSelector((state: any) => state.data.downloadLoader);
    const addRemarksComplaint: boolean = useSelector((state: any) => state.data.addRemarksComplaint);
    const feedbackOpen: boolean = useSelector((state: any) => state.data.feedbackOpen);
    const goGreenPointsOpen: boolean = useSelector((state: any) => state.data.goGreenPointsOpen);
    const [chatBotOpen, setChatBotOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (loginOpen || registerOpen || resetPasswordOpen || verifyEmailOpen || fareOpen || firstlastTime || langOpen || reportItemOpen || newPasswordOpen || complaintDetail || searchOpen || tourOpen || downloadLoader || addRemarksComplaint || feedbackOpen || goGreenPointsOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [loginOpen, registerOpen, resetPasswordOpen, verifyEmailOpen, fareOpen, firstlastTime, langOpen, reportItemOpen, newPasswordOpen, complaintDetail, searchOpen, tourOpen, downloadLoader, addRemarksComplaint, feedbackOpen, goGreenPointsOpen]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {loginOpen && <Login type="passenger" />}
            {registerOpen && <Register type="passenger" />}
            {resetPasswordOpen && <ResetPassword type="passenger" />}
            {newPasswordOpen && <NewPassword type="passenger" />}
            {verifyEmailOpen && <VerifyEmail />}
            {fareOpen && <FareCalculator />}
            {firstlastTime && <FirstLastTime />}
            {langOpen && <LanguageChange />}
            {reportItemOpen && <AddReportItem />}
            {complaintDetail && (window.location.pathname?.includes?.("grievance-complaint") ? <ComplaintDetail type="passenger" /> : <ComplaintDetailComponent type="passenger" />)}
            {searchOpen && <SearchPopup />}
            {tourOpen && <TourDetailPopup />}
            {downloadLoader && <DownloadLoader />}
            {addRemarksComplaint && <AddRemarksComplaint type="passenger" />}
            {feedbackOpen && <Feedback />}
            {goGreenPointsOpen && <GoGreenPointsInfo />}
            <Outlet />
            <Footer type="passenger" />
            {(!!!user || !chatBotOpen) ? <ChatBotIcon setChatBotOpen={setChatBotOpen} chatBotOpen={chatBotOpen} /> : <ChatBot setChatBotOpen={setChatBotOpen} chatBotOpen={chatBotOpen} />}
        </>
    );
};

export default MainLayout;