/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from "react-i18next";
import { ChatCloseSvg, ChatFillLike, ChatFillUnLike, ChatLike, ChatMic, ChatOpt, ChatPause, ChatSend, ChatTitleSvg, ChatUnLike, Clock } from "../svg/AllSvg";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import moment from "moment";
import axios from "axios";
import { url } from "../../../shared/utils/url";
import useWebSocket from "react-use-websocket";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import Cookies from 'js-cookie';
import ChatBotGIF from '../../../assets/images/chatbot.gif';
import { useDeviceType } from "../../../shared/utils/useDeviceType";

const formatChatDate = (date: any) => {
    const now = moment();
    const messageDate = moment(date);

    if (messageDate.isSame(now, "day")) {
        return `Today at ${messageDate.format("h:mm A")}`;
    } else if (messageDate.isSame(moment().subtract(1, "day"), "day")) {
        return `Yesterday at ${messageDate.format("h:mm A")}`;
    } else {
        return messageDate.format("MMM DD, YYYY [at] h:mm A");
    }
}

const ChatBot = ({ setChatBotOpen, chatBotOpen }: any) => {
    const user: any = useSelector((state: any) => state.auth.user);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const { t } = useTranslation('common');
    const [inputFocus, setInputFocus] = useState<boolean>(false)
    let [input, setInput] = useState<string>("");
    const options: any[] = ["Book Ticket", "Check Metro Timing"]
    let [chatList, setChatList] = useState<any[]>(JSON.parse(localStorage.getItem('chat') as any) || []);
    const [loading, setLoading] = useState<boolean>(false);
    const [ip, setIp] = useState<any>("");
    const [socket, setSocket] = useState<any>(false);
    const [titleOpen, setTitleOpen] = useState<boolean>(true);
    const ref: any = useRef();
    const chatListEndRef: any = useRef();
    const dispatch = useDispatch();
    const isMobile = useDeviceType();

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
        useSpeechRecognition();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setChatBotOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    // Establish WebSocket connection
    const { sendMessage, sendJsonMessage } = useWebSocket(`${url?.socketUrl}?authorization=${Cookies.get('usertoken')}&session_type=chat`, {
        onOpen: () => {
            sendJsonMessage({ "lang": lang, "devicetype": "web", "botname": "Mumbai Metro", "domainname": "arohatech" });
            setSocket(true);
            console.log('WebSocket connection established.');
        },
        onMessage: async (message: any) => {
            if (!!message) {
                const msg: any = await JSON.parse(message?.data)
                if (chatList?.length === 0) {
                    const arr: any[] = [...chatList, { id: chatList?.length + 1, msg: msg?.response, time: new Date().toISOString(), isSystem: true, like: false, unLike: false }];
                    storeChatData(arr);
                    setSocket(true);
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                }
                if (chatList?.length > 1 && loading) {
                    const arr: any[] = [...chatList, { id: chatList?.length + 1, msg: msg?.response, time: new Date().toISOString(), isSystem: true, like: false, unLike: false }];
                    storeChatData(arr);
                    setSocket(true);
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
                }
            }
        },
        onError: (error: any) => {
            setLoading(false);
            setSocket(false);
            console.error("WebSocket error:", error);
        },
        onClose: () => {
            console.log("WebSocket connection closed.");
        },
        shouldReconnect: () => true, // Auto-reconnect
    });

    useEffect(() => {
        if (chatListEndRef.current) {
            chatListEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [loading, chatList])

    useEffect(() => {
        const getIP = async () => {
            try {
                const res = await axios.get('https://api.ipify.org?format=json');
                if (res?.status && res?.status === 200) {
                    setIp(res?.data?.ip);
                }
            } catch (error) {
                console.error('Error fetching IP')
            }
        }

        getIP();

        setTimeout(() => { setTitleOpen(false) }, 2000)
    }, [])

    if (!browserSupportsSpeechRecognition) {
        return <p>{t("chat.mic_warn")}</p>;
        // alert("Unable to access microphone. Please check your device and browser settings.");
    }

    const handleLike = async (item: any, type: any) => {
        let arr: any[] = [...chatList]
        let index: any = arr?.findIndex((i: any) => i?.id === item?.id)
        if (!!index || index === 0) {
            if (type === "like") {
                arr[index].like = chatList[index].like === true ? false : true
            } else {
                arr[index].unLike = chatList[index].unLike === true ? false : true
            }
            storeChatData(arr);
        }
    }

    const handleSend = async () => {
        setLoading(true);
        const chatMsg = input;
        input = "";
        setInput(input);
        const arr: any[] = [...chatList, { id: chatList?.length + 1, msg: chatMsg, time: new Date().toISOString(), isSystem: false, like: false, unLike: false }];
        storeChatData(arr);
        const payload = {
            query: chatMsg,
            // lang: lang,
            // devicetype: "web",
            // ip: ip,
            // username: (user?.first_name + " " + user?.last_name),
            source: "text",
            // access_token: "",
            // refresh_token: "",
        };
        sendMessage(JSON.stringify(payload));
    }

    const handleSendVoiceMessage = async () => {
        if (listening) {
            SpeechRecognition.stopListening();

            if (!!transcript) {
                setLoading(true);
                const chatMsg = transcript;
                resetTranscript();
                const arr: any[] = [...chatList, { id: chatList?.length + 1, msg: chatMsg, time: new Date().toISOString(), isSystem: false, like: false, unLike: false }];
                storeChatData(arr);
                const payload = {
                    query: chatMsg,
                    // lang: lang,
                    // devicetype: "web",
                    // ip: ip,
                    // username: (user?.first_name + " " + user?.last_name),
                    source: "voice",
                    // access_token: "",
                    // refresh_token: "",
                };
                sendMessage(JSON.stringify(payload));
            }
        } else {
            SpeechRecognition.startListening({ continuous: true });
        }
    };

    const getLocalStorageSize = () => {
        let total = 0;
        for (let key in localStorage) {
            if (localStorage.hasOwnProperty(key)) {
                let value = localStorage.getItem(key);
                total += key.length + (value ? value.length : 0);
            }
        }
        return (total / 1024).toFixed(2);
    };

    const storeChatData = (chat: any[]) => {
        const size: any = getLocalStorageSize();
        if (+size >= 5120) {
            localStorage.removeItem('chat');
            chatList = chat;
            setChatList(chatList);
            localStorage.setItem('chat', JSON.stringify(chat));
        } else {
            chatList = chat;
            setChatList(chatList);
            localStorage.setItem('chat', JSON.stringify(chat));
        }
    }

    const handleLink = (e: any, url: any) => {
        e.preventDefault();
        dispatch(dataActions.setWebOpen(true));
        dispatch(dataActions.setLink(url));
    }

    // Function to extract bracketed links from text
    const getAllBracketTextsWithLinks = (text: string) => {
        const regex = /\((https?:\/\/[^\s)]+)\)|<([^<>]+)>/g;
        let match;
        let elements: (string | JSX.Element)[] = [];
        let lastIndex = 0;

        while ((match = regex.exec(text)) !== null) {
            // Extract non-link text before the match
            if (match.index > lastIndex) {
                elements.push(text.substring(lastIndex, match.index));
            }

            // Extract the actual link from () or <>
            const linkUrl = match[1] || match[2];
            if (linkUrl) {
                elements.push(
                    <a
                        key={elements.length} // Ensure unique key
                        href={linkUrl}
                        target={!linkUrl.includes(url?.domains?.main) ? "_blank" : "_self"}
                        onClick={(e) =>
                            !linkUrl.includes(url?.domains?.main) && handleLink(e, linkUrl)
                        }
                        className="text-primary focus:underline hover:underline"
                    >
                        {linkUrl}
                    </a>
                );
            }

            lastIndex = regex.lastIndex;
        }

        // Add remaining text after last match
        if (lastIndex < text.length) {
            elements.push(text.substring(lastIndex));
        }

        return elements;
    };


    return (
        <div className="fixed right-[20px] bottom-[30px] z-[4]">
            <div className="relative" ref={ref}>
                <div className="flex items-end xs:items-center w-full justify-end xs:flex-row flex-col ">
                    {(!chatBotOpen && titleOpen) && <div className="px-[12px] xs:px-[16px] py-[8px] xsm:w-fit xsm:whitespace-nowrap bg-primary rounded-[12px] xlx:rounded-[13px] rounded-tr-none xlx:rounded-r-none xs:rounded-r-none xs:mr-[-10px] chat-bubble flex items-center xs:order-1 order-2">
                        <h6 className="text-white font-bold text-text-[0.938rem] xs:sm">{t("chat.title")}</h6>
                    </div>}
                    <button className="rounded-full flex items-center justify-center min-w-[100px] w-[100px] h-[100px] bg-white dark:bg-bgDarkDull dark:focus:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d] dark:hover:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d] focus:shadow-[0px_8px_22px_-6px_#00000040,0px_14px_64px_-4px_#0000000d] hover:shadow-[0px_8px_22px_-6px_#00000040,0px_14px_64px_-4px_#0000000d] xs:order-2 order-1 relative z-[1]" onClick={() => !!user ? setChatBotOpen(true) : dispatch(dataActions.setLoginOpen(true))} aria-label={t("aria_label.aqua_ai")} title={t("aria_label.aqua_ai")} onMouseEnter={() => setTitleOpen(true)} onMouseLeave={() => setTitleOpen(false)} onFocus={() => !isMobile && setTitleOpen(true)}>
                        <img src={ChatBotGIF} alt="chatbot" className="min-w-[100px] w-[100px] h-[100px] ml-[-1.5px]" />
                    </button>
                </div>

                <div className={`${!chatBotOpen ? "translate-y-[110%]" : ""} transition duration-[700] w-[305px] sm:w-[375px] xlx:w-[405px] min-h-[400px] h-[65vh] sm:h-[71vh] absolute right-[-4px] bottom-[-12px] rounded-[20px] rounded-br-none bg-white dark:bg-bgDarkDull shadow-lg flex flex-col justify-between z-[2]`}>
                    <div className="flex items-center w-full min-h-[45px] px-[17px] border-b dark:border-[#454545] border-[#D7D8DD] relative">
                        <button className="min-w-[24px] h-[24px] rounded-full flex items-center justify-center hover:bg-[#dddddd5e] focus:bg-[#dddddd5e] dark:hover:bg-[#dddddd26] dark:focus:bg-[#dddddd26] absolute" aria-label={t("aria_label.close")} title={t("aria_label.close")} onClick={() => setChatBotOpen(false)}><ChatCloseSvg /></button>

                        <div className="flex items-center justify-center w-full">
                            <ChatTitleSvg />
                        </div>

                        {/* <button className="min-w-[24px] h-[24px] rounded-[4px] flex items-center justify-center hover:bg-[#dddddd5e] focus:bg-[#dddddd5e] dark:hover:bg-[#dddddd26] dark:focus:bg-[#dddddd26]" aria-label={t("aria_label.option")} title={t("aria_label.option")}><ChatOpt /></button> */}

                        <div className="w-full top-[45px] left-0 right-0 absolute z-[0] dark:hidden">
                            <img src={require("../../../assets/images/chat-bg.png")} alt="chat-bg" className="w-full dark:mix-blend-multiply" />
                        </div>
                    </div>

                    <div className="h-[calc(100%-109px)] xlx:h-[calc(100%-115px)] w-full overflow-y-auto overflow-x-hidden">
                        <>
                            {(chatList?.length === 0 || chatList?.length === 1) ?
                                <>
                                    {/* when blank chat */}
                                    < div className="flex flex-col gap-[15px] xlx:gap-[20px] z-[1] relative py-[19px] px-[21px]">
                                        <div className="w-full rounded-[10px] bg-[#F8FDFE] dark:bg-blackDark border border-[rgba(0,151,214,0.30)] dark:border-primary p-[15px] xlx:p-[18px] flex flex-col">
                                            <h6 className="text-primary font-bold">{t("chat.empty_chat.card.line1")}</h6>
                                            <p className="text-blackDull font-medium dark:text-textBlackDull">{t("chat.empty_chat.card.line2")} <button className="focus:text-black hover:text-black dark:focus:text-white dark:hover:text-white underline ">{t("chat.empty_chat.card.line3")}</button></p>
                                        </div>

                                        {/* <div className="flex flex-col gap-[8px]">
                                                <h6 className="text-blackDark font-semibold dark:text-textBlackDark">{t("chat.empty_chat.option.title")}</h6>
                                                <div className="flex items-center gap-[5px] flex-wrap">
                                                    {Array.isArray(options) && options?.map((item: any, index: number) => (
                                                        <button className="rounded-full bg-[#E5F2FF] dark:bg-blackDark h-[28px] xlx:h-[30px] px-[12px] xlx:px-[15px] border border-transparent  focus:border-[rgba(0,151,214,0.30)] hover:border-[rgba(0,151,214,0.30)] dark:focus:border-primary dark:hover:border-primary text-primaryText dark:text-white font-semibold text-[0.813rem]" key={index}>{item}</button>
                                                    ))}
                                                </div>
                                            </div> */}
                                    </div>
                                </>
                                :
                                <>
                                    {/* chat */}
                                    <div className="flex flex-col gap-[15px] z-[1] relative py-[19px] px-[21px]">
                                        {Array.isArray(chatList) && chatList?.map((item: any, index: number) => (
                                            <React.Fragment key={index}>
                                                {!item?.isSystem ?
                                                    <div className="w-full flex items-end justify-end">
                                                        <div className="flex flex-col gap-[4px] max-w-[306px] items-end justify-end">
                                                            <div className="rounded-[15px] rounded-br-none bg-primary text-white text-[0.938rem] xlx:text-sm font-medium px-[17px] py-[8px] xlx:py-[11px] w-fit break-anywhere whitespace-pre-line">
                                                                {item?.msg}
                                                            </div>

                                                            <div className="text-blackDull dark:text-textBlackDark text-[0.688rem] xlx:text-[0.625rem] font-semibold gap-[5px] flex items-center"><Clock className="fill-blackDull dark:fill-textBlackDull px-[10px]" /> {formatChatDate(item?.time)}</div>
                                                        </div>
                                                    </div> :
                                                    <>
                                                        <>
                                                            {((chatList?.length - 1) !== index) ? <>
                                                                <div className="w-full flex items-start justify-start">
                                                                    <div className="max-w-[306px] flex flex-col gap-[4px] items-start justify-start">
                                                                        <div className="rounded-[15px] rounded-tl-none bg-[#F7F7F9] dark:bg-blackDark text-blackDull dark:text-textBlackDull text-[0.938rem] xlx:text-sm *:text-[0.938rem] xlx:*:text-sm font-medium *:font-medium px-[17px] py-[8px] xlx:py-[11px] w-fit break-anywhere whitespace-pre-line">{getAllBracketTextsWithLinks(item?.msg)}</div>
                                                                        <div className="flex items-center justify-between w-full gap-[5px] px-[10px]">
                                                                            <div className="text-blackDull dark:text-textBlackDark text-[0.688rem] xlx:text-[0.625rem] font-semibold gap-[5px] flex items-center"><Clock className="fill-blackDull dark:fill-textBlackDull" /> {formatChatDate(item?.time)}</div>
                                                                            {/* <div className="flex items-center">
                                                                                    {(item?.like || (!item?.unLike && !item?.like)) && <button className="w-[25px] h-[25px] min-w-[25px] rounded-[4px] hover:bg-[#dddddd5e] focus:bg-[#dddddd5e] dark:hover:bg-[#dddddd26] dark:focus:bg-[#dddddd26] flex items-center justify-center" onClick={() => handleLike(item, "like")}>{item?.like ? <ChatFillLike /> : <ChatLike />}</button>}
                                                                                    {(item?.unLike || (!item?.unLike && !item?.like)) && <button className="w-[25px] h-[25px] min-w-[25px] rounded-[4px] hover:bg-[#dddddd5e] focus:bg-[#dddddd5e] dark:hover:bg-[#dddddd26] dark:focus:bg-[#dddddd26] flex items-center justify-center" onClick={() => handleLike(item, "unLike")}>{item?.unLike ? <ChatFillUnLike /> : <ChatUnLike />}</button>}
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> :
                                                                <>
                                                                    {!loading &&
                                                                        <div className="w-full flex items-start justify-start">
                                                                            <div className="max-w-[306px] flex flex-col gap-[4px] items-start justify-start">
                                                                                <div className="rounded-[15px] rounded-tl-none bg-[#F7F7F9] dark:bg-blackDark text-blackDull dark:text-textBlackDull text-[0.938rem] xlx:text-sm *:text-[0.938rem] xlx:*:text-sm font-medium *:font-medium px-[17px] py-[8px] xlx:py-[11px] w-fit break-anywhere whitespace-pre-line">{getAllBracketTextsWithLinks(item?.msg)}</div>
                                                                                <div className="flex items-center justify-between w-full gap-[5px] px-[10px]">
                                                                                    <div className="text-blackDull dark:text-textBlackDark text-[0.688rem] xlx:text-[0.625rem] font-semibold gap-[5px] flex items-center"><Clock className="fill-blackDull dark:fill-textBlackDull" /> {formatChatDate(item?.time)}</div>
                                                                                    {/* <div className="flex items-center">
                                                                                            {(item?.like || (!item?.unLike && !item?.like)) && <button className="w-[25px] h-[25px] min-w-[25px] rounded-[4px] hover:bg-[#dddddd5e] focus:bg-[#dddddd5e] dark:hover:bg-[#dddddd26] dark:focus:bg-[#dddddd26] flex items-center justify-center" onClick={() => handleLike(item, "like")}>{item?.like ? <ChatFillLike /> : <ChatLike />}</button>}
                                                                                            {(item?.unLike || (!item?.unLike && !item?.like)) && <button className="w-[25px] h-[25px] min-w-[25px] rounded-[4px] hover:bg-[#dddddd5e] focus:bg-[#dddddd5e] dark:hover:bg-[#dddddd26] dark:focus:bg-[#dddddd26] flex items-center justify-center" onClick={() => handleLike(item, "unLike")}>{item?.unLike ? <ChatFillUnLike /> : <ChatUnLike />}</button>}
                                                                                        </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>}
                                                        </>
                                                    </>}
                                            </React.Fragment>
                                        ))}
                                        {loading &&
                                            <div className="w-full flex items-start justify-start pb-[15px]">
                                                <div className="loader1">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>}
                                    </div>
                                </>
                            }
                        </>
                        <div id="chat-bottom-div" ref={chatListEndRef}></div>
                    </div>

                    <div className="px-[18px] pb-[20px] w-full z-[1] relative">
                        <div className="flex items-center gap-[4px]">
                            {loading ?
                                <div className="h-[43px] xlx:h-[49px] w-full bg-gray-200 rounded-full dark:bg-gray-700 animate-pulse"></div>
                                : <div className={`rounded-full border dark:border-[#454545] border-[#D7D8DD] h-[43px] xlx:h-[49px] w-full relative flex items-center px-[6px] xlx:px-[8px] ${inputFocus ? "bg-white dark:bg-bgDarkDull" : `bg-[#F5F5F5] dark:bg-bgDark ${!listening && "hover:bg-white dark:hover:bg-bgDarkDull"}`}`}>
                                    <form onSubmit={(e: any) => { e.preventDefault(); (!!input && socket && !listening) && handleSend(); }} className="w-full h-full">
                                        <input type="text" className={`rounded-full bg-transparent w-full text-blackDull text-[0.938rem] xlx:text-sm font-semibold leading-snug h-full pl-[12px] xlx:pl-[20px] ${(!!input) ? "pr-[65px]" : "pr-[85px]"} placeholder:text-blackDull dark:text-textBlackDull placeholder:dark:text-textBlackDull`} placeholder={t("chat.placehoder")} autoFocus={true} onFocus={() => setInputFocus(true)} onBlur={() => setInputFocus(false)} value={input} onChange={(e: any) => setInput(e.target.value)} disabled={listening} />

                                        <div className="flex items-center gap-[5px] absolute top-0 right-[6px] xlx:right-[8px] h-[40px] xlx:h-[46px] rounded-r-full justify-end">
                                            <button type="button" onClick={handleSendVoiceMessage} aria-label={listening ? t("aria_label.listening") : t("aria_label.mic")} title={listening ? t("aria_label.listening") : t("aria_label.mic")} className={`min-w-[35px] h-[35px] w-[35px] rounded-full hover:bg-[#dddddd5e] focus:bg-[#dddddd5e] dark:hover:bg-[#dddddd26] dark:focus:bg-[#dddddd26] flex items-center justify-center relative ${listening && "mic-btn"}`}>{listening ? <ChatPause /> : <ChatMic className={`${listening ? "fill-primary" : "fill-[#A3A3A3]"}`} />}</button>

                                            <button type="button" aria-label={t("aria_label.send")} title={!!input ? t("aria_label.send") : ""} className="min-w-[35px] h-[35px] xlx:min-w-[40px] xlx:h-[40px] flex items-center justify-center rounded-full bg-primary dark:bg-primary focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] disabled:focus:shadow-none disabled:hover:shadow-none pl-[4px] disabled:opacity-[0.6]" disabled={(!!input && socket && !listening) ? false : true} onClick={handleSend}><ChatSend /></button>
                                        </div>
                                    </form>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ChatBot;