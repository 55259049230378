import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { useDeviceType } from "../../../shared/utils/useDeviceType";
import ChatBotGIF from '../../../assets/images/chatbot.gif';

const ChatBotIcon = ({ setChatBotOpen, chatBotOpen }: any) => {
    const user: any = useSelector((state: any) => state.auth.user);
    const { t } = useTranslation('common');
    const [titleOpen, setTitleOpen] = useState<boolean>(true);
    const isMobile = useDeviceType();
    const dispatch = useDispatch();

    return (
        <div className="fixed right-[20px] bottom-[30px] z-[4]">
            <div className="flex items-end xs:items-center w-full justify-end xs:flex-row flex-col ">
                {(!chatBotOpen && titleOpen) && <div className="px-[12px] xs:px-[16px] py-[8px] xsm:w-fit xsm:whitespace-nowrap bg-primary rounded-[12px] xlx:rounded-[13px] rounded-tr-none xlx:rounded-r-none xs:rounded-r-none xs:mr-[-10px] chat-bubble flex items-center xs:order-1 order-2">
                    <h6 className="text-white font-bold text-text-[0.938rem] xs:sm">{t("chat.title")}</h6>
                </div>}
                <button className="rounded-full flex items-center justify-center min-w-[100px] w-[100px] h-[100px] bg-white dark:bg-bgDarkDull dark:focus:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d] dark:hover:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d] focus:shadow-[0px_8px_22px_-6px_#00000040,0px_14px_64px_-4px_#0000000d] hover:shadow-[0px_8px_22px_-6px_#00000040,0px_14px_64px_-4px_#0000000d] xs:order-2 order-1 relative z-[1]" onClick={() => !!user ? setChatBotOpen(true) : dispatch(dataActions.setLoginOpen(true))} aria-label={t("aria_label.aqua_ai")} title={t("aria_label.aqua_ai")} onMouseEnter={() => setTitleOpen(true)} onMouseLeave={() => setTitleOpen(false)} onFocus={() => !isMobile && setTitleOpen(true)}>
                    <img src={ChatBotGIF} alt="chatbot" className="min-w-[100px] w-[100px] h-[100px] ml-[-1.5px]" />
                </button>
            </div>
        </div >
    );
};

export default ChatBotIcon;